<template>
  <b-card title="New Word Blacklist">
    <chat-add-word-blacklist />
    <chat-moderator-sdk ref="sdk" />

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  async mounted() {
    await customElements.whenDefined('chat-moderator-sdk')
    const sdk = document.querySelector('chat-moderator-sdk')

    const url = config.VUE_CONFIG_SIGNALR
    const data = {
      partnerId: 'MODERATORS',
      userId: window.localStorage.getItem('userId'),
      name: window.localStorage.getItem('username'),
      currencyCode: 'R$',
      languageCode: 'BRL',
      userType: 2,
    }

    const response = await fetch(`${url}/player/login`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })

    const information = await response.json()
    console.log(information)
    // const parentId = 'TEST'

    await sdk.configure({
      AppId: data.partnerId,
      TokenId: information.tokenId,
      SessionId: information.sessionId,
      SignalRUrl: url,
    })

    const userId = window.localStorage.getItem('userId')
    if (userId === undefined) window.location.href = '/login'

    /*await customElements.whenDefined('bingoroom-running-round')
    const elem = document.querySelector('bingoroom-running-round')
    elem.addEventListener('newWinner', event => {
      event.detail.forEach(x => {
        const txt = `${x.userName} GANO ${x.prizeCode.replace('', '')}`
        this.$bvToast.toast(txt, {
          title: `Nuevo Ganador!! ${x.prizeCode.replace('', '')}`,
          variant: 'success',
          toaster: 'b-toaster-top-right',
          solid: true,
          autoHideDelay: 10000,
        })
      })
    })
    
    await customElements.whenDefined('chat-message-container')
    const chat = document.querySelector('chat-message-container')

    setTimeout(() => {
      chat.addEventListener('newMessage', event => {
        this.$bvToast.toast(event.detail.message, {
          title: `${event.detail.user.name} dice:`,
          variant: 'primary',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          autoHideDelay: 3000,
        })
      })
    }, 5000)
    */
  },  
}
</script>

<style>

</style>
